html, body {
  overflow-x: hidden;
}
body {
  position: relative
}

.App {
  text-align: center;
}

.image-top {
  max-width: 100%;
  height: auto; /* Mantém a proporção da imagem */
  object-fit: cover; /* Opcional: define como a imagem é ajustada no contêiner */
}

.ReactModal__Overlay {
  background-color: #132839 !important;
  opacity: 0.9 !important;
}

.logo {
  width: 230px;
}

.withoutOpacity {
  display: flex;
  flex-direction: column;
  z-index: 999;
  padding: 20px;
}

.inputs {
  background-color: #FFF !important;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
  margin-top: 60px;
}

.header {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
}

.empty {
  width: 20px;
  height: 20px;
}

.modal-texts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-texts h3 {
  padding: 10px;
  margin-bottom: 5px;
  font-size: 18px;
  text-align: center;
  color: #132839;
}

.modal-texts h5 {
  font-size: 15px;
  text-align: center;
  color: #132839;
}

.button-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: none;
  outline: none;
  margin-bottom: 20px;
  background-color: #FFB72D;
  color: #132839;
  font-weight: bold;
}

.centered-text {
  font-size: 18px;
  width: 80%;
  font-style: normal;
  font-weight: 700;
  color: #132839;
  margin-bottom: 30px;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loading {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.slogan {
  color: #132839;
  font-size: 13px;
  font-weight: bold;
}

.modal-backdrop.show {
  background-color: #132839 !important;
  opacity: 0.9 !important;
}

.custom-modal {
  padding: 20px !important;
}

.logo-container {
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo-text {
  text-align: left;
  font-size: 16px;
  color: #132839;
  font-weight: 400;
  line-height: 150%;
}
.logo-text-centered {
  text-align: left;
  font-size: 16px;
  color: #132839;
  font-weight: 400;
  line-height: 150%;
}

.footer {
  /* position: fixed; */
  padding: 10px 10px 0px 10px;
  bottom: 0px;
  margin-bottom: 0px;
  width: 100%;
  background-color: #FFF;
  height: 60px;
}

.footer p {
  color: #9B9B9B;
  font-size: 12px;
}


@media (max-width:500px) {
  .centered-text {
    width: 400px;
  }
  .logo-text {
    width: 330px;
  }
  .logo-text-centered {
    width: 330px;
    text-align: center;
  }
}